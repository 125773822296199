SPACES.MetricsFactory = (function ($) {
  function MetricsFactory () { }

  MetricsFactory.prototype.buildUnitData = function () {
    const $elements = []
    const units = []

    SPACES.$tab.data('isotope').filteredItems.forEach(function (item) {
      $elements.push($(item.element))
    })

    $elements.forEach(function ($elem) {
      const unit = {
        id: $elem.data('spaces-unit-id'),
        unitNumber: $elem.data('spaces-unit').toString(),
        area: $elem.data('spaces-sort-area'),
        areaDisplay: `${Number($elem.data('spaces-sort-area')).toLocaleString('en-US')} Sq. Ft.`,
        price: $elem.data('spaces-sort-price'), // nullable
        priceDisplay: `$${Number($elem.data('spaces-sort-price')).toLocaleString('en-US')}`,
        availableOn: $elem.data('spaces-soonest'), // nullable
        availableOnDisplay: $elem.find('[data-spaces-control="unit-available-on"]').text().replace(/(\r\n|\n|\r)/gm, '').replace(/\s+/g, ' ').trim(),
        floorPlan: {
          id: $elem.data('spaces-plan-id'),
          name: $elem.data('spaces-sort-plan-name'),
          bedroomCount: $elem.data('spaces-sort-bed'),
          bathroomCount: $elem.data('spaces-sort-bed')
        }
      }

      units.push(unit)
    })

    return units
  }

  MetricsFactory.prototype.buildSingleUnitData = function($elem) {
    $elem = $($elem)
    const unit = {
      id: $elem.data('spaces-unit-id'),
      unitNumber: $elem.data('spaces-unit').toString(),
      area: $elem.data('spaces-sort-area'),
      areaDisplay: `${Number($elem.data('spaces-sort-area')).toLocaleString('en-US')} Sq. Ft.`,
      price: $elem.data('spaces-sort-price'), // nullable
      priceDisplay: `$${Number($elem.data('spaces-sort-price')).toLocaleString('en-US')}`,
      availableOn: $elem.data('spaces-soonest'), // nullable
      availableOnDisplay: $elem.find('[data-spaces-control="unit-available-on"]').text().replace(/(\r\n|\n|\r)/gm, '').replace(/\s+/g, ' ').trim(),
      floorPlan: {
        id: $elem.data('spaces-plan-id'),
        name: $elem.data('spaces-sort-plan-name'),
        bedroomCount: $elem.data('spaces-sort-bed'),
        bathroomCount: $elem.data('spaces-sort-bed')
      }
    }

    return unit;
  }

  MetricsFactory.prototype.buildPlanData = function () {
    const $elements = []
    const plans = []

    SPACES.$tab.data('isotope').filteredItems.forEach(function (item) {
      $elements.push($(item.element))
    })

    $elements.forEach(function ($elem) {
      const plan = {
        id: $elem.data('spaces-plan-id'),
        name: $elem.data('spaces-sort-plan-name'),
        bedroomCount: $elem.data('spaces-sort-bed'),
        bathroomCount: $elem.data('spaces-sort-bed'),
        availableOn: $elem.data('spaces-soonest'), // nullable
        price: $elem.data('spaces-sort-price'), // nullable
      }

      plans.push(plan)
    })

    return plans
  }

  MetricsFactory.prototype.buildSinglePlanData = function($elem) {
    $elem = $($elem)
    const plan = {
      id: $elem.data('spaces-plan'),
      name: $elem.data('spaces-sort-plan-name'),
      bedroomCount: $elem.data('spaces-sort-bed'),
      bathroomCount: $elem.data('spaces-sort-bed'),
      availableOn: $elem.data('spaces-soonest'), // nullable
      price: $elem.data('spaces-sort-price'), // nullable
    }

    return plan;
  }

  return MetricsFactory
})(jQuery)
