SPACES.FilterFactory = (function ($) {
  function FilterFactory () {
    SPACES.$filterControls = SPACES.$controls.filter

    this.bindFilterControls()
    this.bindControlResetFilters()

    SPACES.filterArray = []
    SPACES.filterString = ''

    SPACES.sightmapFilter = []

    if (SPACES.spacesConfig.initial_sightmap) {
      SPACES.sightmapFilter = SPACES.spacesConfig.initial_sightmap
    }
  }

  FilterFactory.prototype.filterUnit = (_unitCSS, _filterArray) => _filterArray.every(v => _unitCSS.includes(v.replace('.', '')))

  FilterFactory.prototype.doFilter = function () {
    SPACES.utility.log('SPACES doFilter filter.js:21')

    SPACES.filterFactory.setFilterArray()

    SPACES.filterString = SPACES.filterArray.join('')

    if (SPACES.filterString) {
      $('[data-spaces-control="control-header"]').addClass('spaces-filtered')
    } else {
      $('[data-spaces-control="control-header"]').removeClass('spaces-filtered')
    }

    SPACES.utility.log('SPACES Filter String: ', SPACES.filterString)

    if (SPACES.spacesConfig.sightmap_url) {
      if (SPACES.currentTab !== 'community') {
        SPACES.filterFactory.setSightMapFilter()
      }
    }

    if (SPACES.currentTab === 'community') {
      SPACES.utility.log('SPACES: Should run setCommunityFilter')
      SPACES.filterFactory.setCommunityFilter({ overrideFilter: true })
    }

    if (SPACES.spacesConfig.inventory_mode && SPACES.currentTab === 'unit') {
      SPACES.filterFactory.setCommunityFilter()
    }

    if (SPACES.currentTab === 'map') {
      SPACES.sightmapFactory.embed.setUnitIdMatches(SPACES.sightmapFilter)
    } else {
      if (SPACES.currentTab !== 'plan-detail' && SPACES.currentTab !== 'unit-detail' && SPACES.currentTab !== 'apply') {
        SPACES.utility.log('SPACES: Calling ISO doFilter filter.js:47')
        SPACES.isoFactory.doFilter()
      }
    }

    SPACES.utility.log('SPACES: DO filter complete filter.js:59')
  }

  FilterFactory.prototype.filterByDate = function (_currentFilterVal) {
    const selectedDate = new Date(_currentFilterVal)

    $('[data-spaces-obj]').each(function () {
      const currentItem = $(this)
      const floorPlanDate = new Date($(this).data('spaces-soonest'))

      if (floorPlanDate < selectedDate) {
        currentItem.addClass('available')
      } else {
        currentItem.removeClass('available')
      }
    })

    SPACES.spacesUnitJSON.forEach(function (_obj) {
      const availableOn = new Date(_obj.available_on)

      if (availableOn < selectedDate) {
        _obj.css.push('available')
      } else {
        _obj.css = _obj.css.filter(item => item !== 'available')
      }
    })

    return '.available'
  }

  FilterFactory.prototype.setFilterArray = function () {
    // Clear any previous filters
    SPACES.filterArray = []

    Object.keys(SPACES.spacesQuery).forEach(function (key) {
      if (key !== 'spaces_tab' && key !== 'movein' && key !== 'spaces_sort') {
        // Only push the filter if it is a class value, ignore other query string parameters
        if (SPACES.spacesQuery[key].includes('.')) {
          SPACES.filterArray.push(SPACES.spacesQuery[key])
          // Update a select with the value
          $(`select[data-spaces-filter="${key}"]`).each(function (){
            $(this).val(SPACES.spacesQuery[key]).dropdown('set selected', SPACES.spacesQuery[key])
          })
        }
      }

      if (key === 'movein' && SPACES.spacesQuery[key]) {
        $('[data-spaces-filter="movein"]').each(function () {
          const $this = $(this)
          $this.val(SPACES.utility.formatCalendarDate(SPACES.spacesQuery[key]))
          // $this.val(SPACES.spacesQuery[key])
          $this.datepicker('update')
        })
        SPACES.filterArray.push(SPACES.filterFactory.filterByDate(SPACES.spacesQuery[key]))
      }

      if (key === 'spaces_sort') {
        // Update the select with the sort value
        const sortVal = SPACES.queryFactory.getParameterByName('spaces_sort')
        $('select[data-spaces-control="sort"]').each(function () {
          const $select = $(this)
          $select.val(sortVal)
        })
      }
    })

    SPACES.utility.log('SPACES Filter Array:', SPACES.filterArray)
  }

  FilterFactory.prototype.setSightMapFilter = function () {
    if (SPACES.filterArray.length > 0) {
      SPACES.sightmapFilter = SPACES.spacesConfig.initial_sightmap.filter(function (unitId) {
        const unitArr = SPACES.spacesUnitJSON.filter(obj => { return obj.id === unitId })
        if (unitArr.length > 0) {
          return SPACES.filterFactory.filterUnit(unitArr[0].css, SPACES.filterArray)
        }
      })
    } else {
      // all filters have been cleared
      SPACES.sightmapFilter = SPACES.spacesConfig.initial_sightmap
    }

    SPACES.utility.log('SPACES SightMap Filter: ', SPACES.sightmapFilter)
  }

  FilterFactory.prototype.setCommunityFilter = function (_opts = { overrideFilter: false }) {
    SPACES.communityFilterClassArr = []
    SPACES.communityFilterUnits = {}

    if (SPACES.filterArray.length > 0) {
      const ids = SPACES.spacesUnitJSON.map(u => u.id)

      SPACES.communityFilter = ids.filter(function (unitId) {
        const unitArr = SPACES.spacesUnitJSON.filter(obj => { return obj.id === unitId })
        if (unitArr.length > 0) {
          return SPACES.filterFactory.filterUnit(unitArr[0].css, SPACES.filterArray)
        }
      })

      SPACES.spacesUnitJSON.forEach(function (unit) {
        if (SPACES.communityFilter.includes(unit.id)) {
          if(!SPACES.communityFilterUnits[unit.community]) {
            SPACES.communityFilterUnits[unit.community] = []
          }

          SPACES.communityFilterUnits[unit.community].push(unit)

          if (!SPACES.communityFilterClassArr.includes(`.spaces-community-${unit.community}`)) {
            SPACES.communityFilterClassArr.push(`.spaces-community-${unit.community}`)
          }

          $(`.spaces-community-${unit.community}`).attr('data-spaces-filtered-unit-count', SPACES.communityFilterUnits[unit.community].length)
        }
      })

      if (_opts.overrideFilter) {
        if (SPACES.communityFilterClassArr.length > 0) {
          SPACES.filterString = SPACES.communityFilterClassArr.join(',')
        } else {
          SPACES.filterString = '.spaces-none'
        }
      }

      if (SPACES.communityFilterClassArr.length > 0) {
        Object.entries(SPACES.communityFilterUnits).forEach(function (community) {
          if (community[1].length > 0) {
            $(`.spaces-community-${community[0]}`).find('.spaces-community-cta').find('.spaces-button-label').text(`View ${community[1].length} Units`)
          }
        })
      }
      
      SPACES.utility.log('SPACES Community Filter Classes: ', SPACES.communityFilterClassArr)
    } else {
      // reset all community unit counts back to the original
      $('[data-spaces-obj="community"]').each(function () {
        const $community = $(this)
        if ($community.data('spaces-community-unit-count') !== '0') {
          $community.find('.spaces-community-cta').find('.spaces-button-label').text(`View ${$community.data('spaces-community-unit-count')} Units`)
        }
      })
    }
  }

  FilterFactory.prototype.filterCommunities = function (_elements) {
    SPACES.utility.log('SPACES: Community view active for filters filter.js:121')
    // filter the map pins

    // Get the list of isotope filtered elements, we will get the IDs
    // from each element and use the resuting array to filter the map
    // markers based on what is visible

    // clear any previous communities
    SPACES.filteredCommunities = []

    // SPACES.utility.log('SPACES: Filtered community cards filter.js:133', SPACES.$tab.data('isotope').filteredItems)

    _elements.forEach(function (_community) {
      const $elem = $(_community.element)
      const communityID = $elem.data('community-id')

      if (communityID) {
        SPACES.filteredCommunities.push(communityID)
      }
    })

    SPACES.utility.log('SPACES filtered communities: fitler.js:221', SPACES.filteredCommunities)

    // clear any previous filtered communities
    SPACES.filteredCommunityJSON = []
    // create a filtered list of visible communty data
    SPACES.filteredCommunityJSON = SPACES.communityJSON.filter(function (community) {
      return SPACES.filteredCommunities.includes(community.id)
    })

    // Clear all the current markers
    SPACES.controlFactory.clearCommunityMapMarkers()

    // reload the markers with the filtered data
    SPACES.controlFactory.addCommunityMapMarkers({ filtered: true })
    // SPACES.controlFactory.updateAssetFilter({ filtered: true })

    SPACES.utility.log('SPACES: community map filter complete filter.js:77')
  }

  FilterFactory.prototype.bindFilterControls = function () {
    const factory = this

    SPACES.$filterControls.each(function () {
      const $control = $(this)

      $control.unbind() // Unbind any previous bindings

      const filter = $control.data('spaces-filter')
      const controlType = $control[0].type

      switch (controlType) {
        case 'select-one':
          if (filter === 'asset') {
            factory.bindCommunityFilter($control)
          } else {
            factory.bindFilterSelect($control)
          }
          break
        case 'text':
          if (filter === 'movein') {
            SPACES.$filterControls.movein = $control
            factory.bindFilterDate($control)
          }
          break
        case 'date':
          if (filter === 'movein') {
            SPACES.$filterControls.movein = $control
            factory.bindFilterDateMobile($control)
          }
          break
        case 'checkbox':
          factory.bindFilterCheck($control)
          break
      }
    })

    // factory.bindControlMoveIn()
  }

  FilterFactory.prototype.bindControlMoveIn = function () {
    if (SPACES.isDevice) {
      return
    }
    // Clicking on the parent control clears the date
    if (typeof (SPACES.$controls.moveIn) !== 'undefined') {
      SPACES.$controls.moveIn.click(function () {
        $(this).removeClass('has_date')
        $('.spaces__select').removeClass('open')
        SPACES.queryFactory.removeQString('movein')
        SPACES.$filterControls.movein.val('')
        SPACES.filterFactory.doFilter()
      })
    }
  }

  FilterFactory.prototype.bindFilterSelect = function (_$control) {
    _$control.dropdown()
    _$control.change(function () {
      const val = $(this).val()
      const filter = $(this).data('spaces-filter')

      if (val !== '' && val !== '*') {
        SPACES.queryFactory.changeUrl(filter, val)
      } else {
        SPACES.queryFactory.removeQString(filter)
      }

      SPACES.filterFactory.doFilter()
    })
  }

  FilterFactory.prototype.bindCommunityFilter = function (_$control) {
    _$control.change(function () {
      const val = $(this).val()
      const filter = $(this).data('spaces-filter')

      if (val !== '' && val !== '*') {
        SPACES.queryFactory.changeUrl(filter, val)
      } else {
        SPACES.queryFactory.removeQString(filter)
      }

      SPACES.filterFactory.doFilter()
    })
  }

  FilterFactory.prototype.bindFilterCheck = function (_$control) {
    _$control.change(function () {
      const val = $(this).val()
      const filter = $(this).data('spaces-filter')
      if (this.checked) {
        SPACES.queryFactory.changeUrl(filter, val)
      } else {
        SPACES.queryFactory.removeQString(filter)
      }

      SPACES.filterFactory.doFilter()
    })
  }

  FilterFactory.prototype.bindFilterDate = function (_$control) {
    const startDate = new Date()

    const maxDaysOut = parseInt(SPACES.spacesConfig.spaces_option_max_days_out)

    const endDate = SPACES.utility.addDays(startDate, maxDaysOut)

    _$control.unbind()

    const pickerArgs = {
      autoHide: true,
      startDate: startDate,
      endDate: endDate,
      container: '#spaces-picker-container'
    }

    if (_$control.attr('id') === 'spaces_movein_date_filter_modal') {
      pickerArgs.container = '#spaces-picker-container-modal'
      pickerArgs.inline = true
    }

    _$control.datepicker(pickerArgs).on('pick.datepicker', function (e) {
      const dt = e.date
      const filter = $(this).data('spaces-filter')
      let str

      if (dt) {
        const d = new Date(dt)
        str = d.toJSON().slice(0, 10)
      }

      if (dt !== '' && dt !== '*') {
        SPACES.queryFactory.changeUrl(filter, str)
      } else {
        SPACES.queryFactory.removeQString(filter)
      }

      SPACES.$controls.moveIn.addClass('has_date')

      SPACES.filterFactory.doFilter()
    })
  }

  FilterFactory.prototype.bindFilterDateMobile = function (_$control) {
    _$control.unbind()

    _$control.change(function () {
      const val = $(this).val()
      const filter = $(this).data('spaces-filter')

      let dtStr = false
      if (val) {
        const d = new Date(val)
        dtStr = d.toJSON().slice(0, 10)
      }
      
      if (dtStr !== '' && dtStr !== '*') {
        SPACES.queryFactory.changeUrl(filter, dtStr)
        if (SPACES.utility.isDate(val)) {
          SPACES.utility.log('SPACES: Selected Date:', val)
          SPACES.queryFactory.changeUrl(filter, val)
        }
      } else {
        SPACES.queryFactory.removeQString(filter)
      }

      SPACES.filterFactory.doFilter()
    })
  }

  FilterFactory.prototype.bindControlResetFilters = function () {
    const factory = this

    if (typeof (SPACES.$controls.resetFilters) === 'undefined') {
      return
    }

    SPACES.$controls.resetFilters.click(function (e) {
      e.preventDefault()

      SPACES.utility.log('SPACES: Reset filters')

      factory.clearQuery()
      factory.clearFilterControls()

      factory.doFilter()
    })
  }

  FilterFactory.prototype.clearQuery = function () {
    Object.keys(SPACES.spacesQuery).forEach(function (_key) {
      if (_key !== 'spaces_tab' && _key !== 'spaces_sort' && _key !== 'spaces-market') {
        SPACES.queryFactory.removeQString(_key)
      }
    })
  }

  FilterFactory.prototype.clearFilterControls = function () {
    SPACES.$filterControls.each(function () {
      const $control = $(this)
      const controlType = $control[0].type

      switch (controlType) {
        case 'select-one':
          $control.dropdown('set selected', '*')
          break
        case 'text':
          $control.val('')
          break
        case 'date':
          $control.val('')
          break
      }
    })
  }

  return FilterFactory
})(jQuery)
