// Handles firing a metrics event when the details link is click.

SPACES.UnitDetailCTA = (function ($) {
  function UnitDetailCTA () {
    this.$controlElement = $('[data-spaces-control="unit-detail-cta"]')
  }

  UnitDetailCTA.prototype.init = function () {
    this.$controlElement.each(function () {
      $cta = $(this)
      $cta.on('click', function () {
        const metrics = { name: 'metrics.unit.details.click', data: { unit: SPACES.metrics.buildSingleUnitData($cta.parents('[data-spaces-obj="unit"]')) } }
        SPACES.emit('metrics.unit.details.click', metrics)
      })
    })
  }

  return UnitDetailCTA
})(jQuery)

document.addEventListener('spaces_init', function (e) {
  // Hook into the main SPACES init
  SPACES.unitDetailCTA = new SPACES.UnitDetailCTA()
  SPACES.unitDetailCTA.init()
})

document.addEventListener('spaces_tab', function (e) {
  // Run init after AJAX tab switch
  SPACES.unitDetailCTA = new SPACES.UnitDetailCTA()
  SPACES.unitDetailCTA.init()
})