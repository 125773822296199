/* global spacesConfig spacesQuery spacesUnitJSON spacesCommunityJSON  CustomEvent */

import './vendor/accordion.js'
import './vendor/datepicker.js'
import './vendor/fancybox.js'
import './vendor/select.js'

import './utility.js'
import './query.js'
import './isotope.js'
import './tab.js'
import './spacesbox.js'
import './ajax.js'
import './sort.js'
import './control.js'
import './sightmap.js'
import './filter.js'
import './v2/metrics/metrics.js'

import './v2/controls/mobile-list.js'
import './v2/controls/disclaimer.js'
import './v2/controls/unit-explore-menu.js'
import './v2/controls/unit-detail-link.js'
import './v2/controls/unit-detail-sightmap.js'
import './v2/controls/share-email.js'
import './v2/controls/copy-to-clipboard.js'
import './v2/controls/inventory-map-toggle.js'
import './v2/controls/inventory-list-toggle.js'
import './v2/controls/sort.js'
import './v2/controls/filter-modal-reset.js'
import './v2/controls/knock-schedule-tour.js'
import './v2/controls/unit-number.js'
import './v2/controls/return-to-search.js'
import './v2/controls/community-card.js'
import './v2/controls/community-sightmap-trigger.js'
import './v2/controls/unit-sightmap-trigger-portfolio.js'
import './v2/controls/community-unit-trigger.js'
import './v2/controls/unit-sightmap-trigger.js'
import './v2/controls/community-view-switcher.js'
import './v2/controls/plan-detail-cta.js'
import './v2/controls/unit-detail-cta.js'
import './v2/controls/unit-apply-cta.js'
import './v2/spaces-modal-focus.js'
import './v2/spaces-modal.js'
import Emitter from './v2/emitter.js'

window.SPACES || (window.SPACES = {})

const hash = window.location.hash

// SPACES.emitter = require('./v2/emitter.js')
// SPACES.emitter(SPACES)

Emitter(SPACES)

SPACES.init = function () {
  if (typeof (spacesConfig) === 'undefined') {
    return // Not on SPACES don't do scripts
  }

  SPACES.isMobile = jQuery(window).width() < 1024
  SPACES.isDevice = navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)
  SPACES.evInit = new CustomEvent('spaces_init', { detail: 'spaces init start' })
  SPACES.evTab = new CustomEvent('spaces_tab', { detail: 'spaces tab switch' })

  SPACES.spacesConfig = spacesConfig
  SPACES.spacesQuery = spacesQuery
  SPACES.spacesUnitJSON = spacesUnitJSON
  SPACES.communityJSON = false
  if (typeof spacesCommunityJSON !== 'undefined') {
    SPACES.commnuityFilterIDs = []
    SPACES.commnuityFilterUnits = []
    SPACES.communityFilterClassArr = []
    SPACES.communityJSON = spacesCommunityJSON
    SPACES.filteredCommunityJSON = {}
    SPACES.filteredCommunities = []
    SPACES.communityMap = null
    SPACES.markers = []
    SPACES.bounds = null
  }

  SPACES.sightmapLoaded = false
  // SPACES.lastUrl = window.sessionStorage.getItem('spacesLastUrl')

  SPACES.utility = new SPACES.UtilityFactory()

  SPACES.utility.log('HELLO FROM SPACES')
  SPACES.utility.log('SPACES configured with:', SPACES.spacesConfig)

  SPACES.queryFactory = new SPACES.QueryFactory() // A class to manage the query string
  SPACES.isoFactory = new SPACES.IsoFactory()
  SPACES.tabFactory = new SPACES.TabFactory() // A class to manage the plan, unit and map "views"
  SPACES.spacesBoxFactory = new SPACES.SpacesBoxFactory()
  SPACES.ajaxFactory = new SPACES.AjaxFactory()
  SPACES.sortFactory = new SPACES.SortFactory()
  SPACES.controlFactory = new SPACES.ControlFactory()

  SPACES.lastTab = SPACES.currentTab
  // SPACES.lastQuery = window.location.search

  if (SPACES.currentTab === 'map') {
    SPACES.sightmapFactory = new SPACES.SightMapFactory()
  }

  SPACES.metrics = new SPACES.MetricsFactory()

  // Allow controls to bind before interacting with the filter factory
  SPACES.emit('init', { name: 'init', data: { message: 'SPACES is initialized' } } )
  document.dispatchEvent(SPACES.evInit)

  if (SPACES.currentTab !== 'plan-detail' && SPACES.currentTab !== 'unit-detail' && SPACES.currentTab !== 'apply') {
    SPACES.isoFactory.bindIsotope()
    SPACES.filterFactory = new SPACES.FilterFactory()
    if (SPACES.spacesQuery) {
      SPACES.utility.log('SPACES: DO Initial Filter')
      SPACES.filterFactory.doFilter() // Do the initial filter on load if we have values
      // SPACES.sortFactory.doSort()
    }
  }
  // jQuery('[data-spaces-select]').spacesSelect()
  // jQuery('select[data-spaces-select]').dropdown()

  // scroll to the SPACES anchor if on detail or apply
  const spacesScrollViews = ['unit-detail', 'plan-detail', 'apply']
  if (spacesScrollViews.includes(SPACES.spacesQuery.spaces_tab)) {
    const $anchor = jQuery('[data-spaces-control="anchor"]')
    jQuery('html,body').animate({ scrollTop: $anchor.offset().top }, 'slow')
  }

  SPACES.emit('ready', { name: 'ready', data: { message: 'SPACES is ready' } } )
}

jQuery(window).on('load', function () {
  if (hash.includes('spaces_anchor')) {
    window.location.hash = window.location.hash.replace(/#spaces_anchor/g, '')
    const $anchor = jQuery('[data-spaces-control="anchor"]')
    jQuery('html,body').animate({ scrollTop: $anchor.offset().top - 120 }, 'slow')
  }
})

jQuery(function () {
  SPACES.init()
})
